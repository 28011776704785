<template>
  <div class="flex items-center gap-4 min-h-32">
    <div class="flex flex-col gap-4">
      <div v-if="statusLabelText && statusLabelText.length > 0" class="flex items-center gap-8">
        <span
          v-if="isReviewing || isApplying || isVerifyRequest || isReject"
          class="inline-flex items-center px-12 py-2 rounded-full text-sm font-bold leading-md"
          :class="{
            'bg-[#FFC226] text-on-surface-elevation-2': isReviewing,
            'bg-brand-primary text-white': isApplying,
            'bg-blue50 text-blue600': isVerifyRequest,
            'bg-tint-red-a400 text-white': isReject
          }"
        >
          {{ $t(`${statusLabelText}`) }}
        </span>

        <p
          v-if="statusInfoText && statusInfoText.length > 0"
          class="text-xs font-medium leading-xs text-on-surface-elevation-2"
        >
          {{ $t(`${statusInfoText}`, { dateTime: updatedDt }) }}
        </p>
      </div>
      <p
        v-if="priceTableGuideText && priceTableGuideText.length > 0"
        class="text-xs leading-xs text-placeholder"
      >
        {{ $t(`${priceTableGuideText}`) }}
      </p>
    </div>
    <div class="flex gap-8 ml-auto">
      <s-button
        v-if="hasBtnModify"
        size="sm"
        variant="outline"
        class="shrink-0 min-w-52"
        :isDisabled="isBtnDisabled || isDisabledModifyButton"
        @click="emits('modify')"
      >
        {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_edit_btn') }}
      </s-button>
      <s-button
        v-if="hasBtnDelete"
        size="sm"
        variant="outline"
        class="shrink-0 min-w-52"
        :isDisabled="isBtnDisabled"
        @click="emits('delete')"
      >
        {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_delete_btn') }}
      </s-button>
      <s-button
        v-if="hasBtnCancel"
        size="sm"
        variant="outline"
        class="shrink-0 min-w-52"
        :isDisabled="isBtnDisabled"
        @click="emits('cancel')"
      >
        {{ $t('studio.common.popup_case_cancel_btn') }}
      </s-button>
    </div>
  </div>
</template>
<script setup lang="ts">
defineProps<{
  isReviewing?: boolean;
  isReleaseReviewing?: boolean;
  isApplying?: boolean;
  isVerifyRequest?: boolean;
  isReject?: boolean;
  statusLabelText?: string;
  statusInfoText?: string;
  priceTableGuideText?: string;
  hasBtnModify?: boolean;
  hasBtnDelete?: boolean;
  hasBtnCancel?: boolean;
  isBtnDisabled?: boolean;
  isDisabledModifyButton?: boolean;
  updatedDt?: string;
}>();

const emits = defineEmits<{
  modify: [];
  cancel: [];
  delete: [];
}>();
</script>
