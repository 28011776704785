<template>
  <s-button
    v-if="!itemInfo"
    variant="primary"
    size="md"
    class="!min-w-[11.6rem] absolute right-0 top-[-85px]"
    @click="onRegisterPriceInfo"
  >
    {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_reg_btn') }}
  </s-button>
  <template v-if="hasReasonRejection && rejectReason?.rejectContents && !isLoading">
    <reason-rejection
      v-for="(rejectContent, index) in rejectReason.rejectContents.filter(
        (content) =>
          content.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.PRICE ||
          content.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.ETC
      )"
      :key="index"
      :class="index < rejectReason.rejectContents.length - 1 ? 'mb-16' : '-mb-16'"
      :reasonType="`${rejectContent.verifyTargetType}`"
      :reason="rejectContent.reason"
      :reasonTitle="rejectContent.title"
    />
  </template>
  <st-box v-if="!isLoading" :class="{ 'mt-40': hasReasonRejection }">
    <div v-if="!itemInfo" class="flex flex-col gap-20 items-center py-[3.4rem]">
      <img
        src="@/assets/images/selling-price/selling-price-entry-image.svg"
        alt=""
        class="w-272 h-188 mx-auto object-contain"
      />
      <safe-html
        tag="p"
        class="text-3xl font-bold leading-2xl text-on-surface-elevation-1 text-center"
        :html="$t('studio.prj_prod.this_prod.prod_mgmt_price_landing_guide1')"
      />
    </div>

    <template v-else>
      <div v-if="!itemInfo.notVerifyPassedPrice || !itemInfo.verifyPassedPrice">
        <price-table-header
          :priceTableGuideText="priceTableHeaderInfo.priceTableGuideText"
          hasBtnModify
          :hasBtnDelete="priceTableHeaderInfo.isReject"
          :statusLabelText="priceTableHeaderInfo.statusLabelText"
          :statusInfoText="priceTableHeaderInfo.statusInfoText"
          :isApplying="priceTableHeaderInfo.isApplying"
          :isReject="priceTableHeaderInfo.isReject"
          :isReviewing="priceTableHeaderInfo.isReviewing"
          :isVerifyRequest="priceTableHeaderInfo.isVerifyRequest"
          :isDisabledModifyButton="isDisabledModifyButton"
          :updatedDt="
            priceTableHeaderInfo.isReject
              ? formatDateTime(itemInfo.notVerifyPassedPrice?.verifyRejectedAt, DATE_TIME_DOMESTIC)
              : formatDateTime(itemInfo.verifyPassedPrice?.releaseStartedAt, DATE_TIME_DOMESTIC)
          "
          @modify="onEditPriceInfo"
          @delete="onDeletePriceInfo"
          @cancel="onCancelPriceInfo"
        />
        <!-- CASE: No request, free -->
        <dl
          v-if="
            itemInfo.verifyPassedPrice?.paidYn === Confirmation.NO ||
              itemInfo.notVerifyPassedPrice?.paidYn === Confirmation.NO
          "
          class="flex flex-col gap-8 mt-20 p-20 bg-[var(--stds-glob-color-gray40)] rounded-2xl"
        >
          <dt class="text-sm font-medium leading-md text-on-surface-elevation-4">
            {{
              $t(
                'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_header1'
              )
            }}
          </dt>
          <dd class="text-md font-bold leading-lg text-on-surface-elevation-2">
            {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_reg_sales_type_free_msg') }}
          </dd>
        </dl>
        <!-- CASE: No request, payment -->
        <div v-else class="mt-16">
          <st-table
            :columns="columns"
            :data="
              itemInfo?.verifyPassedPrice?.priceList ||
                itemInfo?.notVerifyPassedPrice?.priceList ||
                []
            "
          >
            <template #row="{ row }">
              <st-tr>
                <st-td
                  class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                >
                  <store-display-price-info
                    :flagImage="flagImages[row.displayCurrencyCode]"
                    :countryName="hasMultiplePrices ? '' : COUNTRY_BY_CURRENCY[row.displayCurrencyCode as CurrencyCode]"
                    :currencyCode="row.displayCurrencyCode"
                    :displayPriceValue="row.displayPrice"
                  />
                </st-td>
                <st-td
                  class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                >
                  <payment-price-info
                    :currencyCode="
                      row.displayCurrencyCode !== CurrencyCode.Krw
                        ? CurrencyCode.Usd
                        : CurrencyCode.Krw
                    "
                    :paymentPriceValue="row.salesPrice"
                  />
                </st-td>
              </st-tr>
            </template>
          </st-table>
        </div>
      </div>

      <div v-else class="grid grid-cols-2 gap-40">
        <div class="col-span-1">
          <price-table-header
            :statusLabelText="priceTableHeaderInfo.statusLabelText"
            :statusInfoText="priceTableHeaderInfo.statusInfoText"
            :isApplying="priceTableHeaderInfo.isApplying"
            :updatedDt="
              formatDateTime(itemInfo.verifyPassedPrice?.releaseStartedAt, DATE_TIME_DOMESTIC)
            "
          />
          <!-- CASE: No request, free -->
          <dl
            v-if="itemInfo.verifyPassedPrice?.paidYn === Confirmation.NO"
            class="flex flex-col gap-8 mt-20 p-20 bg-[var(--stds-glob-color-gray40)] rounded-2xl"
          >
            <dt class="text-sm font-medium leading-md text-on-surface-elevation-4">
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_header1'
                )
              }}
            </dt>
            <dd class="text-md font-bold leading-lg text-on-surface-elevation-2">
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_msg'
                )
              }}
            </dd>
          </dl>
          <!-- CASE: No request, payment -->
          <div v-else class="mt-16">
            <st-table
              :columns="columns"
              :data="
                itemInfo?.verifyPassedPrice?.priceList ||
                  itemInfo?.notVerifyPassedPrice?.priceList ||
                  []
              "
            >
              <template #row="{ row }">
                <st-tr>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <store-display-price-info
                      :flagImage="flagImages[row.displayCurrencyCode]"
                      :countryName="hasMultiplePrices ? '' : COUNTRY_BY_CURRENCY[row.displayCurrencyCode as CurrencyCode]"
                      :currencyCode="row.displayCurrencyCode"
                      :displayPriceValue="row.displayPrice"
                    />
                  </st-td>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <payment-price-info
                      :currencyCode="
                        row.displayCurrencyCode !== CurrencyCode.Krw
                          ? CurrencyCode.Usd
                          : CurrencyCode.Krw
                      "
                      :paymentPriceValue="row.salesPrice"
                    />
                  </st-td>
                </st-tr>
              </template>
            </st-table>
          </div>
        </div>

        <div class="col-span-1">
          <price-table-header
            :updatedDt="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REJECT
                ? formatDateTime(itemInfo.notVerifyPassedPrice.verifyRejectedAt, DATE_TIME_DOMESTIC)
                : undefined
            "
            :statusLabelText="priceTableHeaderRequestInfo.statusLabelText"
            :statusInfoText="priceTableHeaderRequestInfo.statusInfoText"
            :isReject="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REJECT
            "
            :isReviewing="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.START
            "
            :isVerifyRequest="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REQUEST
            "
            :hasBtnCancel="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.START ||
                itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REQUEST
            "
            :isBtnDisabled="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.START
            "
            :hasBtnModify="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REJECT ||
                itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.NONE
            "
            :hasBtnDelete="
              itemInfo.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REJECT
            "
            @modify="onEditPriceInfo"
            @delete="onDeletePriceInfo"
            @cancel="onCancelPriceInfo"
          />
          <dl
            v-if="itemInfo.notVerifyPassedPrice?.paidYn === Confirmation.NO"
            class="flex flex-col gap-8 mt-20 p-20 bg-[var(--stds-glob-color-gray40)] rounded-2xl"
          >
            <dt class="text-sm font-medium leading-md text-on-surface-elevation-4">
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_header1'
                )
              }}
            </dt>
            <dd class="text-md font-bold leading-lg text-on-surface-elevation-2">
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_msg'
                )
              }}
            </dd>
          </dl>
          <div v-else class="mt-16">
            <st-table :columns="columns" :data="itemInfo.notVerifyPassedPrice.priceList || []">
              <template #row="{ row }">
                <st-tr>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <store-display-price-info
                      :flagImage="flagImages[row.displayCurrencyCode]"
                      :countryName="hasMultiplePrices ? '' : COUNTRY_BY_CURRENCY[row.displayCurrencyCode as CurrencyCode]"
                      :currencyCode="row.displayCurrencyCode"
                      :displayPriceValue="row.displayPrice"
                    />
                  </st-td>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <payment-price-info
                      :currencyCode="
                        row.displayCurrencyCode !== CurrencyCode.Krw
                          ? CurrencyCode.Usd
                          : CurrencyCode.Krw
                      "
                      :paymentPriceValue="row.salesPrice"
                    />
                  </st-td>
                </st-tr>
              </template>
            </st-table>
          </div>
        </div>
      </div>
    </template>
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import {
  deleteProductSellingPriceApi,
  fetchProductSellingPriceApi
} from '@/apis/product-selling-price.api';
import { fetchProductStatusApi } from '@/apis/products.api';
import { cancelReviewRequestApi, fetchLatestRejectionInfoApi } from '@/apis/review.api';
import * as ImageAssets from '@/assets/images/selling-price';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import PaymentPriceInfo from '@/components/selling-price/payment-price-info.vue';
import PriceTableHeader from '@/components/selling-price/price-table-header.vue';
import ReasonRejection from '@/components/selling-price/reason-rejection.vue';
import StoreDisplayPriceInfo from '@/components/selling-price/store-display-price-info.vue';
import { useApp } from '@/composables/useApp';
import { showConfirm } from '@/composables/useDialog';
import { COUNTRY_BY_CURRENCY, currencyCodes } from '@/constants/currency-codes.const';
import { DATE_TIME_DOMESTIC } from '@/constants/date-time.const';
import { VERIFY_STATUS } from '@/constants/products.const';
import { RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE } from '@/constants/release-plan.const';
import { PRICE_ITEM_REQUEST_STATUS, PRICE_ITEM_STATUS } from '@/constants/selling-price.const';
import { PRODUCT_SELLING_PRICE_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { useAppStore } from '@/stores/app.store';
import { useCurrencyStore } from '@/stores/currency.store';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { PriceListType } from '@/types/product-selling-price/product-selling-price.type';
import type { ProductSellingPriceResponse } from '@/types/product-selling-price/product-selling-price-response.type';
import type { LatestRejectionInfoResponse } from '@/types/review/review-response.type';
import { redirectTo } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';

definePageMeta({
  middleware: 'handle-product-data-middleware'
});

const { t } = useI18n();

const route = useRoute();

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const appStore = useAppStore();
const { checkProductPermission } = useApp();

const { isLoading } = storeToRefs(appStore);

const { productId } = route.params;

const columns = [
  {
    title: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_header1'
  },
  { title: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_checkout_price_header2' }
];

const hasReasonRejection = ref<boolean>(false);
const exchangeRates = ref<CurrencyInfoModel[]>();
const rejectReason = ref<LatestRejectionInfoResponse>();
const itemInfo = ref<ProductSellingPriceResponse>();
const flagImages = ref<Record<string, string>>(ImageAssets);
const priceTableHeaderInfo = ref({
  priceTableGuideText: '',
  statusLabelText: '',
  statusInfoText: '',
  isApplying: false,
  isReject: false,
  isReviewing: false,
  isVerifyRequest: false
});
const priceTableHeaderRequestInfo = ref({
  statusLabelText: '',
  statusInfoText: ''
});

const isDisabledModifyButton = ref(false);

const hasMultiplePrices = computed(() => {
  return !!itemInfo.value?.verifyPassedPrice && !!itemInfo.value?.notVerifyPassedPrice;
});

const onRegisterPriceInfo = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  redirectTo(`${PRODUCT_SELLING_PRICE_PAGE_URL}/register`);
};

const onEditPriceInfo = () => {
  if (!itemInfo.value) {
    return;
  }

  redirectTo(`${PRODUCT_SELLING_PRICE_PAGE_URL}/edit`);
};

const onCancelPriceInfo = async () => {
  const result = await showConfirm({
    content: t('studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_cancel_pop_msg'),
    confirmLabel: t(
      'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_cancel_pop_withdraw_review_btn'
    ),
    cancelVariant: 'outline'
  });

  if (result) {
    const cancelRequest = await cancelReviewRequestApi({
      productNo: Number(productId),
      verifyType: 'PRICE',
      target: itemInfo.value?.notVerifyPassedPrice?.salesNo.toString() || ''
    });
    if (cancelRequest) {
      await init();
    }
  }
};

const onDeletePriceInfo = async () => {
  const result = await showConfirm({
    content: t('studio.prj_prod.this_prod.prod_mgmt_price_delete_pop_msg'),
    confirmLabel: t('studio.prj_prod.this_prod.prod_mgmt_price_delete_pop_delete_btn'),
    cancelVariant: 'outline',
    confirmVariant: 'red'
  });

  if (result) {
    const deleteResult = await deleteProductSellingPriceApi(Number(productId));
    if (deleteResult) {
      await init();

      hasReasonRejection.value = false;
      rejectReason.value = undefined;
    }
  }
};

const init = async () => {
  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);

  try {
    const res = await fetchProductSellingPriceApi(Number(productId));

    if (!res) {
      itemInfo.value = undefined;
    }

    const releasePlanRes = await fetchProductStatusApi(Number(productId));
    if (releasePlanRes && releasePlanRes.verifyStatus) {
      isDisabledModifyButton.value =
        releasePlanRes.verifyStatus === VERIFY_STATUS.START ||
        releasePlanRes.verifyStatus === VERIFY_STATUS.REQUEST;
    }

    if (res?.notVerifyPassedPrice?.verifyStatus === PRICE_ITEM_STATUS.REJECT) {
      const latest = await fetchLatestRejectionInfoApi({
        productNo: Number(productId),
        verifyType: 'PRICE',
        target: res.notVerifyPassedPrice.salesNo.toString()
      });

      if (latest) {
        hasReasonRejection.value = true;
        rejectReason.value = latest;
      }
    }

    if (res) {
      const tmp = {
        notVerifyPassedPrice: res.notVerifyPassedPrice
          ? {
            ...res.notVerifyPassedPrice,
            priceList: Object.keys(COUNTRY_BY_CURRENCY).map((code: string) => ({
              displayCurrencyCode: code,
              displayPrice:
                  res.notVerifyPassedPrice?.priceList.find(
                    (price: PriceListType) => price.displayCurrencyCode === code
                  )?.displayPrice || 0,
              salesCurrencyCode: res.notVerifyPassedPrice?.priceList.find(
                (price: PriceListType) => price.displayCurrencyCode === code
              )?.salesCurrencyCode,
              salesPrice:
                  res.notVerifyPassedPrice?.priceList.find(
                    (price: PriceListType) => price.displayCurrencyCode === code
                  )?.salesPrice || 0
            }))
          }
          : undefined,
        verifyPassedPrice: res.verifyPassedPrice
          ? {
            ...res.verifyPassedPrice,
            priceList: Object.keys(COUNTRY_BY_CURRENCY).map((code: string) => ({
              displayCurrencyCode: code,
              displayPrice:
                  res.verifyPassedPrice?.priceList.find(
                    (price: PriceListType) => price.displayCurrencyCode === code
                  )?.displayPrice || 0,
              salesCurrencyCode: res.verifyPassedPrice?.priceList.find(
                (price: PriceListType) => price.displayCurrencyCode === code
              )?.salesCurrencyCode,
              salesPrice:
                  res.verifyPassedPrice?.priceList.find(
                    (price: PriceListType) => price.displayCurrencyCode === code
                  )?.salesPrice || 0
            }))
          }
          : undefined
      };

      itemInfo.value = tmp;

      if (itemInfo.value.verifyPassedPrice && !itemInfo.value.notVerifyPassedPrice) {
        priceTableHeaderInfo.value = {
          priceTableGuideText:
            itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REQUEST
              ? 'studio.prj_prod.this_prod.prod_mgmt_price_msg1'
              : '',
          statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_active_msg',
          statusInfoText: 'studio.prj_prod.this_prod.prod_mgmt_price_active_from_msg',
          isApplying: itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.PASS,
          isReject: itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REJECT,
          isReviewing: itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.START,
          isVerifyRequest:
            itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REQUEST
        };
      }

      if (itemInfo.value.notVerifyPassedPrice && itemInfo.value.verifyPassedPrice) {
        priceTableHeaderInfo.value = {
          // priceTableGuideText: 'studio.prj_prod.this_prod.prod_mgmt_price_msg1',
          priceTableGuideText: '',
          statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_active_msg',
          statusInfoText: 'studio.prj_prod.this_prod.prod_mgmt_price_active_from_msg',
          isApplying: itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.PASS,
          isReject: itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REJECT,
          isReviewing: itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.START,
          isVerifyRequest:
            itemInfo.value.verifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REQUEST
        };
        switch (itemInfo.value.notVerifyPassedPrice.verifyStatus) {
          case PRICE_ITEM_REQUEST_STATUS.NONE:
            priceTableHeaderRequestInfo.value = {
              statusLabelText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg1',
              statusInfoText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg2'
            };
            break;

          case PRICE_ITEM_REQUEST_STATUS.REQUEST:
            priceTableHeaderRequestInfo.value = {
              statusLabelText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg1',
              statusInfoText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg2'
            };
            break;

          case PRICE_ITEM_REQUEST_STATUS.START:
            priceTableHeaderRequestInfo.value = {
              statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_active_in_review_msg1',
              statusInfoText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg2'
            };
            break;

          case PRICE_ITEM_REQUEST_STATUS.REJECT:
            priceTableHeaderRequestInfo.value = {
              statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_declined_review_msg',
              statusInfoText:
                'studio.prj_prod.this_prod.prod_mgmt_price_declined_review_timestamp_msg'
            };
            break;
        }
      }

      if (!itemInfo.value.verifyPassedPrice && itemInfo.value.notVerifyPassedPrice) {
        let info;
        switch (itemInfo.value.notVerifyPassedPrice.verifyStatus) {
          case PRICE_ITEM_REQUEST_STATUS.NONE:
            info = {
              statusLabelText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg1',
              statusInfoText: '',
              priceTableGuideText: 'studio.prj_prod.this_prod.prod_mgmt_price_msg1'
            };
            break;

          case PRICE_ITEM_REQUEST_STATUS.REQUEST:
            info = {
              statusLabelText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg1',
              statusInfoText:
                'studio.prj_prod.this_prod.prod_mgmt_price_active_awaiting_review_msg2'
            };
            break;

          case PRICE_ITEM_REQUEST_STATUS.START:
            info = {
              statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_active_in_review_msg1',
              statusInfoText: '',
              priceTableGuideText: 'studio.prj_prod.this_prod.prod_mgmt_price_msg1'
            };
            break;

          case PRICE_ITEM_REQUEST_STATUS.REJECT:
            info = {
              statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_declined_review_msg',
              statusInfoText: ''
            };
            break;
        }

        priceTableHeaderInfo.value = {
          priceTableGuideText: '',
          statusLabelText: '',
          statusInfoText: '',
          isApplying: itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.PASS,
          isReject: itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REJECT,
          isReviewing: itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.START,
          isVerifyRequest:
            itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REQUEST,
          ...info
        };

        if (res.notVerifyPassedPrice?.verifyStatus === PRICE_ITEM_REQUEST_STATUS.REJECT) {
          hasReasonRejection.value = true;
          priceTableHeaderInfo.value = {
            priceTableGuideText:
              itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REQUEST
                ? 'studio.prj_prod.this_prod.prod_mgmt_price_msg1'
                : '',
            statusLabelText: 'studio.prj_prod.this_prod.prod_mgmt_price_declined_review_msg',
            statusInfoText:
              'studio.prj_prod.this_prod.prod_mgmt_price_declined_review_timestamp_msg',
            isApplying: itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.PASS,
            isReject: itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REJECT,
            isReviewing:
              itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.START,
            isVerifyRequest:
              itemInfo.value.notVerifyPassedPrice.verifyStatus === PRICE_ITEM_STATUS.REQUEST
          };
        }
      }
    }
  } catch (error) {
    itemInfo.value = undefined;
  }
};

init();
</script>
